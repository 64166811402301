<template>
  <v-dialog 
    v-model="toggle"
    fullscreen 
    hide-overlay 
    transition="slide-y-reverse-transition"
  >
    <v-sheet
      id="doc-view"
      max-height="100%"
      class="sheet fill-height d-flex flex-column"
    >
      <v-toolbar
        :elevation="toolbarElevation"
        color="transparent"
        class="page-header flex-grow-0 flex-shrink-1"
      >
        <v-expand-x-transition>
          <v-btn
            icon
            @click.stop="toggleView(false)"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </v-expand-x-transition>
        <v-spacer />
        <v-toolbar-title class="title text-body-1">
          {{ title | decodeWeek }}
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            @click.stop="toggleHelp(true, 'signup')"
            icon
            class="mr-n3"
          >
            <v-icon v-text="icons.mdiHelpCircleOutline" />
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      
      <v-card-text 
        id="doc-scroll"
        class="content text-center px-8 d-flex flex-column justify-center align-center flex-grow-1 flex-shrink-0"
        ref="content"
      >
        <v-img 
          v-if="photo.toggle"
          :src="photo.url ? photo.url : null" 
          contain
          class="photo pa-4 rounded"
        >
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                :size="36"
                :width="4"
                color="secondary"
                indeterminate
                style="opacity: .75"
              />
            </v-row>
          </template>
        </v-img>
        <div 
          v-else
          class="d-flex flex-column align-center"
          @click="takePhoto"
        >
          <v-icon 
            color="primary"
            size="96"
            class="icon-camera text--secondary pa-6 rounded-circle"
          >
            {{ icons.mdiCameraPlusOutline }}
          </v-icon>
          <span 
            v-html="$options.filters.decodeWeek(subtitle)"
            class="body-1 my-8"
          />
          <v-btn 
            text
            color="secondary"
            class="btn-example"
            small
            @click.stop="toggleExample(true)"
          >
            Ver exemplo
          </v-btn>
        </div>
      </v-card-text>

      <v-card-actions 
        v-if="status=='ENR'"
        class="controls pa-4"
      >
        <v-btn 
          block
          color="primary"
          class="btn-photo grey--text text--darken-4"
          large
          :disabled="!!doc ? doc.loading : false"
          @click="takePhoto"
        >
          Tirar foto
        </v-btn>
      </v-card-actions>
    </v-sheet>
    <v-dialog 
      v-model="example.toggle"
      hide-overlay 
      transition="slide-y-reverse-transition"
    >
      <v-card>
        <v-btn
          fab
          small
          absolute
          top
          right
          elevation="4"
          class="mt-8"
          @click="toggleExample(false)"
        >
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
        <v-img 
          v-if="hasExample"
          :src="`/img/example-docs/${doc.example}`"
          max-height="72vh"
          contain
          class="example"
        />
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<style>

  #doc-view {
    height: 100% !important;
  }

  #doc-view .v-toolbar {
    z-index: 1;
  }

  #doc-view .content {
    padding: 16px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    max-height: calc(100vh - 56px);
  }

  #doc-view .photo {
    max-height: calc(100vh - 56px);
  }

  #doc-view .icon-camera {
    border: 4px dashed var(--v-grey-darken3);
  }

  #doc-view .controls {
    /* position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: .75; */
  }

</style>

<script>

  // Icons
  import {
    mdiClose,
    mdiHelpCircleOutline,
    mdiCameraPlusOutline
  } from '@mdi/js'

  // Utilities
  import services from '@/services.js'
  import { get, sync } from 'vuex-pathify'
  import device from 'mobile-device-detect';
  const moment = require('moment');
  import _ from 'lodash';

  // import {  
  //   Plugins,  
  //   CameraSource,  
  //   CameraResultType,
  //   CameraDirection
  // } from "@capacitor/core";
  // const { Camera } = Plugins;
  import { Camera, CameraSource, CameraResultType, CameraDirection } from '@capacitor/camera';


  export default {
    name: 'DocView',

    props: {
      toggle: {
        type: Boolean,
        default: false,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      doc: {
        type: Object,
        default: () => null,
      },
      status: {
        type: String,
        default: null,
      },
    },

    data: () => ({
      api: process.env.VUE_APP_ROOT_API,
      icons: {
        mdiClose,
        mdiHelpCircleOutline,
        mdiCameraPlusOutline
      },
      photo: {
        toggle: false,
        loading: false,
        disabled: false,
        url: null
      },
      example: {
        toggle: false,
        fullscreen: false,
      },
      ios: device.isIOS,
      installed: typeof mobees != 'undefined',
    }),

    computed: {
      views: sync('app/views'),
      help: get('help/sections'),
      toast: sync('app/toast'),

      title () {
        return this.doc != null ? this.doc.title : '';
      },
      subtitle () {
        return this.doc != null ? this.doc.subtitle : '';
      },
      hasExample () {
        console.log(!!this.doc&&_.has(this.doc, 'example'))
        return !!this.doc&&_.has(this.doc, 'example');
      },

      toolbarElevation () {
        return this.offsetTop >= 16 ? 2 : 0;
      }
    },

    watch: {
      doc: function (doc) {
        console.log(doc);
      },
      toggle: function (v) {
        if (v) {
            if (!!this.doc&&this.doc.status!=3&&this.doc.url_midia!='') {
            this.photo.url = this.doc.url_midia;
            this.photo.toggle = true;
          }else{
            this.photo.toggle = false;
            this.photo.url = null;
          }
        }
      }
    },

    filters: {
      decodeWeek (text) {
        if (text.indexOf('*')>=0) {
          const breakdown = text.split('*');
          const n = parseInt(breakdown[1].substr(-1, 1));
          const start = moment().startOf('week').subtract(n, 'w').add(1, 'd');
          const end = moment().endOf('week').subtract(n, 'w').add(1, 'd');
          const format = start.month()==end.month() ? 'DD' : 'DD/MM';
          const week = start.format(format) + ' - ' + end.format('DD/MM');
          return text.replace('*week-'+n+'*', week);
        }else{
          return text;
        }
      }
    },

    methods: {
      ...services,

      toggleView (b) {
        // this.toggle = b;
        this.$emit('close');
      },

      toggleExample (b) {
        this.example.toggle = b;
      },

      async takePhoto () {
        const photo = await Camera.getPhoto({
          quality: 80,
          width: 1080,
          preserveAspectRatio: true,
          allowEditing: false,
          resultType: CameraResultType.DataUrl,
          source: CameraSource.Prompt,
          direction: CameraDirection.Rear,
        });
        this.photo.toggle = true;
        this.photo.url = photo.dataUrl;

        if (photo.format=='jpeg'||photo.format=='png') {
          this.$emit('send', {
            doc: this.doc.criteria,
            filename: `${this.doc.criteria}.${photo.format}`,
            blob: photo.dataUrl.split('base64,')[1]
          });

          setTimeout(vue => {
            vue.toggleView();
          }, 500, this);
        }else{
          this.toggleToast(
            true,
            'O formato de imagem '+ photo.format +' não é permitido. Favor enviar JPEG ou PNG.',
            5000,
            false,
          );
        }
      },
    },

    mounted () {

    },

  }
</script>
